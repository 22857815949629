import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/states");
  }
  get(id) {
    return axios.get(`/states/${id}`);
  }
  create(data) {
    return axios.post("/states", data);
  }
  update(id, data) {
    return axios.put(`/states/${id}`, data);
  }
}
export default new DataService();